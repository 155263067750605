<template>
    <div class="multi-carousel">
        <div ref="container" class="grid-container flex items-stretch flex-nowrap">
            <div ref="initialContent" class="grid-row multi-carousel__base-cards w-full flex-shrink-0">
                <Carousel
                    :options="{
                        slides: {
                            autoWidth: true,
                            perView: 'auto',
                            spacing: 16
                        },
                        breakpoints: {
                            '(min-width: 768px)': {
                                slides: {
                                    autoWidth: false,
                                    perView: 3,
                                    spacing: 32
                                }
                            }
                        }
                    }"
                    :desktop-arrows="true"
                    class="relative w-full"
                    @slide-change="globalIndex = $event"
                >
                    <div
                        v-for="(card, index) in cards"
                        :key="`card-multi-${index}`"
                        :class="[`number-slide${index}`, {'multi-carousel__card--hide': index > globalIndex + 2 || index < globalIndex}]"
                        class="multi-carousel__card relative keen-slider__slide overflow-hidden"
                        @click="showCarousel(index)"
                    >
                        <div class="relative bg-grey-98 h-full cursor-pointer">
                            <div class="multi-carousel__card-image relative">
                                <img
                                    v-if="card.image"
                                    :src="card.image.url"
                                    :alt="card.image.alt"
                                    :width="card.image.width"
                                    :height="card.image.height"
                                    loading="lazy"
                                />
                            </div>
                            <h3 v-if="card.heading" class="h4 multi-carousel__card-heading flex items-end text-blue-dark ml-6 pr-6 pt-6 pb-4 border-b border-blue-dark">{{ card.heading }}</h3>
                            <div v-if="card.text" class="multi-carousel__card-text body-2 text-blue-dark px-6 pt-4 pb-10" v-html="card.text" />
                            <button class="btn-text text-grey-20 absolute bottom-6 left-8">{{ card.ctaLabel }}</button>
                        </div>
                    </div>
                </Carousel>
            </div>

            <div ref="content" class="multi-carousel__sub ml-16 lg:ml-24 flex-shrink-0 w-full h-full">
                <div ref="subcarousels" class="grid-row h-full w-full opacity-0">
                    <div class="grid-col relative w-full">
                        <div
                            ref="subcarouselContainer"
                            v-for="(card, index) in cards"
                            :key="`sub-carousel-${index}`"
                            class="multi-carousel__sub-carousel-container bg-white w-full px-4 py-8 overflow-hidden absolute w-full h-full"
                        >
                            <p class="h4 text-blue-dark text-center">{{ card.heading }}</p>

                            <button class="absolute top-4 right-4 md:top-8 md:right-8" @click="closeCarousel">
                                <img src="~@/static/images/icons/close.svg" class="w-4 h-4 md:w-6 md:h-6" aria-label="Fermer" />
                            </button>

                            <Carousel
                                :options="{
                                    mode: 'free-snap',
                                    slides: {
                                        autoWidth: true,
                                        perView: 'auto',
                                        spacing: 16
                                    },
                                    breakpoints: {
                                        '(min-width: 768px)': {
                                            slides: {
                                                autoWidth: true,
                                                perView: 'auto',
                                                spacing: 0
                                            }
                                        }
                                    }
                                }"
                                :mobile-dots="true"
                                :desktop-dots="true"
                                :desktop-arrows="true"
                                class="multi-carousel__sub-carousel relative mt-6"
                            >
                                <article
                                    v-for="i in getNbSlides(card.carouselImages.length)"
                                    :key="`subslide-${i}`"
                                    :class="`number-slide${i}`"
                                    class="multi-carousel__sub-slide relative keen-slider__slide overflow-hidden flex justify-between flex-wrap"
                                >
                                    <div v-for="n in 6" :key="`image-slide-${i}-${n}`" class="multi-carousel__sub-image relative overflow-hidden">
                                        <template v-if="card.carouselImages[((i-1) * 6) + n - 1]">
                                            <img
                                                :src="card.carouselImages[((i-1) * 6) + n - 1].url"
                                                :alt="card.carouselImages[((i-1) * 6) + n - 1].alt"
                                                width="254"
                                                height="194"
                                                class="w-full h-full object-contain md:w-auto md:h-auto md:object-none"
                                                loading="lazy"
                                            />
                                            <div class="multi-carousel__sub-image-title body-2 bg-blue-dark text-white w-full py-3 px-4 absolute top-full left-0 right-0 transition-transform duration-300">
                                                {{ card.carouselImages[((i-1) * 6) + n - 1].alt }}
                                            </div>
                                        </template>
                                    </div>
                                </article>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import Carousel  from '@/app/components/Carousel.vue';

    export default {
        name: 'MultiCarousel',

        components: { Carousel },

        props: {
            cards: { type: Array, default: () => [] }
        },

        data: () => ({
            breakpoint: 992,
            carouselShown: false,
            globalIndex: 0
        }),

        mounted() {
            window.addEventListener('resize', this.resizeHandler);
        },

        methods: {
            getNbSlides(nbItems) {
                return Math.max(Math.ceil(nbItems / 6), 1);
            },

            showCarousel(index) {
                // Slide the contents
                gsap.to([this.$refs.initialContent, this.$refs.content], {
                    x: - this.$refs.initialContent.clientWidth - 48
                });

                // Hide initial content on mobile and set pointer events none
                gsap.to(this.$refs.initialContent, {
                    opacity: (window.innerWidth < this.breakpoint) ? 0 : 1,
                    pointerEvents: 'none'
                })

                // Show the container of subcarousel
                gsap.to(this.$refs.subcarousels, {
                    opacity: 1
                });

                // Hide all subcarousels
                gsap.to(this.$refs.subcarouselContainer, {
                    display: 'none',
                    opacity: 0
                })

                // Show the right subcarousel
                gsap.to(this.$refs.subcarouselContainer[index], {
                    display: 'block',
                    opacity: 1
                });

                this.carouselShown = true;
            },

            closeCarousel() {
                // Reset placement for the contents
                gsap.to([this.$refs.initialContent, this.$refs.content], {
                    x: 0
                });

                // Reset attributes for inital content
                gsap.to(this.$refs.initialContent, {
                    opacity: 1,
                    pointerEvents: 'initial'
                })

                // Hide all subcarousels
                gsap.to(this.$refs.subcarousels, {
                    opacity: 0
                });

                // Hide subcarousel container
                gsap.to(this.$refs.subcarouselContainer, {
                    display: 'none',
                    opacity: 0
                });

                this.carouselShown = false ;
            },

            resizeHandler() {
                if (this.carouselShown) {
                    gsap.set([this.$refs.initialContent, this.$refs.content], {
                        x: - this.$refs.initialContent.clientWidth - 48
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    .multi-carousel {
        &__card {
            min-width: 320px;
            max-width: 320px;
            height: 530px;

            @screen md {
                min-width: 360px;
                max-width: 360px;
                height: 551px;
            }

            &:hover {
                .multi-carousel__card-image:before,
                .multi-carousel__card-image:after {
                    opacity: 1;
                }
            }
        }

        &__base-cards {
            .carousel__arrow-container {
                @apply w-full;
            }

            .btn-arrow--left {
                left: -30px;
            }

            .btn-arrow--right {
                right: -30px;
            }
        }

        &__card--hide {
            @apply opacity-50;
        }


        &__sub {
            height: 551px;
        }

        &__card-image {
            &:before {
                content: '';
                @apply block w-full h-full absolute inset-0 opacity-0 transition-opacity duration-300 pointer-events-none;
                background: rgba(28, 28, 28, 0.4);
            }

            &:after {
                content: '';
                @apply block w-16 h-16 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 pointer-events-none;
                background-image: url('~@/static/images/icons/loupe.svg')
            }
        }

        &__card-heading {
            height: 6.4375rem;
        }


        &__sub-carousel {
            @apply w-full mx-auto;
            max-width: 791px;

            .carousel__content {
                @apply overflow-hidden;
            }

            .carousel__arrow-container {
                @apply w-full;
            }

            .carousel__arrow-right {
                @apply -right-28;
            }

            .carousel__arrow-left {
                @apply -left-28;
            }
        }

        &__sub-slide {
            min-width: 100%;
            max-width: 100%;
            height: 404px;
        }

        &__sub-image {
            width: calc(50% - 10px);
            height: 125px;

            @screen md {
                width: calc(33.333% - 10px);
                height: 194px;

                &:nth-child(n+4) {
                    @apply mt-4
                }
            }

            &:hover {
                .multi-carousel__sub-image-title {
                    @apply transform -translate-y-full;
                }
            }
        }
    }
</style>
