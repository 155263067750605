<template>
    <div ref="carousel" class="carousel">
        <div
            ref="container"
            :class="{'xl:flex xl:justify-center': desktopCenter && carousel && slidesWidth < carouselWidth}"
            class="carousel__content keen-slider"
            :style="centerAdjust"
        >
            <slot></slot>
        </div>

        <div
            v-if="carousel && carousel.track.details && desktopArrows"
            :class="{'hidden md:block': desktopArrows}"
            class="carousel__arrow-container absolute top-1/2 transform -translate-y-1/2 w-full"
        >
            <button
                v-show="currentIndex > 0 && carousel.track.details.min <= carousel.track.details.position"
                class="carousel__arrow-left btn-arrow btn-arrow--left absolute top-1/2 left-0 transform -translate-y-1/2"
                aria-label="Précédent"
                @click="carousel.prev()"
            />
            <button
                v-show="currentIndex < carousel.track.details.maxIdx && slidesWidth >= carouselWidth"
                class="carousel__arrow-right btn-arrow btn-arrow--right absolute top-1/2 right-0 transform -translate-y-1/2"
                aria-label="Suivant"
                @click="carousel.next()"
            />
        </div>
        <div
            v-if="carousel && (mobileDots || desktopDots)"
            :class="{'md:hidden': mobileDots && !desktopDots}"
            class=" w-full flex justify-center items-center mt-6 mx-auto"
        >
            <button
                v-for="(dot, index, key) in carousel.track.details.slides.length"
                :key="key"
                :class="{'ml-4': index > 0, 'bg-blue-dark': index === currentIndex, 'bg-grey-80': index !== currentIndex}"
                class="w-2 h-2 rounded-full"
                :aria-label="`Slide numéro ${index}`"
                @click="goTo(index)"
            ></button>
        </div>
    </div>
</template>

<script>
    import imagesLoaded from 'imagesloaded';
    import 'keen-slider/keen-slider.min.css';
    import KeenSlider from 'keen-slider';

    export default {
        props: {
            options: { type: Object, default: () => {}},
            mobileDots: { type: Boolean, default: false },
            desktopDots: { type: Boolean, default: false },
            desktopArrows: { type: Boolean, default: false },
            desktopCenter: { type: Boolean, default: false }
        },

        data: () => ({
            carousel: null,
            currentIndex: 0,
            progress: 0,
            autoTimer: 4,
            autoInterval: null,
            opts: {},
            slidesWidth: 0,
            carouselWidth: 0,
            centerAdjust: ''
        }),

        computed: {
            centerValue() {
                return (this.carousel && this.desktopCenter)
                    ? (this.carousel.track.details.slides.length * this.options.slides.spacing) / 2
                    : 0
            }
        },

        watch: {
            progress() {
                // Check that progress doesn't go over 100%
                if (this.progress > 1) this.progress = 1;
            },

            currentIndex() {
                // Emit event when current index change
                this.$emit('slide-change', this.currentIndex);
            }
        },

        mounted() {
            // Copy options
            this.opts = JSON.parse(JSON.stringify(this.options));

            // Add events
            this.opts.initial = this.currentIndex;

            // On slide change, update current index
            this.opts.slideChanged = (s) => {
                this.currentIndex = s.track.details.rel;
            }

            this.opts.created = () => {
                // When images are loaded
                imagesLoaded('.carousel', () => {
                    // Reupdate the carousel with the params
                    this.carousel.update(this.opts, 0);

                    this.$nextTick(() => {
                        // Get the width of carousel elements
                        this.updateWidths();
                    })

                    // Set resize event
                    window.addEventListener('resize', this.updateWidths);
                });
            }

            // Init the carousel
            this.carousel = new KeenSlider(this.$refs.container, this.opts);

            if (this.desktopCenter) {
                this.centerAdjust = window.innerWidth > 1200 ? `margin-left: -${this.centerValue}px;` : '';
            }
        },

        beforeUnmount() {
            if (this.carousel) this.carousel.destroy()
        },

        methods: {
            // Move the carousel to the index in parameter
            goTo(index) {
                if (this.carousel) {
                    this.carousel.moveToIdx(index);
                }
            },

            updateWidths() {
                // Get slides
                const slides = this.$el.querySelectorAll('.keen-slider__slide');

                if (slides.length > 0 && this.$refs.carousel) {
                    // Get all slides width
                    this.slidesWidth =  (slides[0].clientWidth * slides.length);
                    // Get carousel width
                    this.carouselWidth = this.$refs.carousel.clientWidth;

                    if (this.desktopCenter) {
                        this.centerAdjust = window.innerWidth > 1200 ? `margin-left: -${this.centerValue}px;` : '';
                    }
                }

                this.carousel.update();
            }
        }
    }
</script>

<style lang="scss">
    .keen-slider:not([data-keen-slider-disabled]) {
        @apply overflow-visible;
    }
</style>
