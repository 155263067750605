<template>
    <header ref="header" class="header-nav fixed top-0 left-0 right-0 z-20 w-full">
        <div
            ref="dimmer"
            class="header-nav__dimmer overlay-grey-11-40 fixed inset-0 z-10 hidden opacity-0 cursor-pointer"
            @click="menuState = false"
        ></div>
        <div
            ref="menuContainer"
            class="relative h-full flex justify-between xl:justify-start items-center px-6 lg:pl-16 lg:pr-0 z-20 bg-white"
        >
            <div class="header-nav__logo-container">
                <slot name="logo"></slot>
            </div>

            <button class="header-nav__burger-menu lg:hidden" @click="menuState = !menuState">
                <div :class="{'transform rotate-41': menuState}" class="transition-transform duration-300"></div>
                <div :class="{'opacity-0': menuState}" class="transition-opacity duration-300"></div>
                <div :class="{'transform -rotate-41': menuState}" class="transition-transform duration-300"></div>
            </button>

            <div ref="menu" class="header-nav__menu overflow-y-scroll lg:overflow-visible hidden">
                <nav v-if="pageSlug && pageSlug === '__home__'" id="mainmenu" class="header-nav__menu-content flex flex-col lg:flex-row lg:items-center">
                    <div
                        v-for="(item, index, key) in menu"
                        :key="`header-link-${key}`"
                    >
                        <a
                            :href="item.url"
                            :target="item.target"
                            :class="{
                                'mt-6 lg:mt-0 lg:ml-8 xl:ml-12' : index > 0,
                                'current': item.url === currentUrl || currentAnchor === item.url
                            }"
                            class="header-nav__nav-item text-nav text-blue-dark header-close"
                            @click="checkAnchor"
                        >{{ item.label }}</a>
                    </div>
                </nav>
                <div class="header-nav__right-part mt-6 lg:mt-0 lg:ml-6 xl:ml-12 lg:h-full">
                    <a
                        v-if="rightLink && rightLink.url"
                        :href="rightLink.url"
                        :target="rightLink.target"
                        class="btn text-nav-active lg:h-full whitespace-nowrap lg:px-4 xl:px-8"
                        @click="checkAnchor"
                    >
                        {{ rightLink.label }}
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { gsap } from '@lahautesociete/gsap';
import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';
import { ScrollTrigger } from '@lahautesociete/gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export default {
    name: 'HeaderNav',

    props: {
        pageSlug: { type: String, required: true },
        menu: { type: Array, required: true },
        rightLink: { type: Object, required: true },
        currentUrl: { type: String, default: '/'}
    },

    data: () => ({
        menuState: false,
        breakpoint: 992,
        currentAnchor: '',
        anchorBlocks : []
    }),

    watch: {
        menuState() {
            // Show/hide the dimmer
            gsap.to(this.$refs.dimmer, {
                display: (this.menuState) ? 'block' : 'none',
                opacity: (this.menuState) ? 1 : 0,
                duration: 0.4,
                ease: 'power2.out'
            });

            // Prevent from scrolling
            gsap.set('html, body', {
                overflow: (this.menuState) ? 'hidden' : 'visible'
            });

            // on mobile
            if (window.innerWidth < this.breakpoint) {
                // Show/hide mobile menu
                gsap.to(this.$refs.menu, {
                    display: (this.menuState) ? 'flex' : 'none',
                    x: (this.menuState) ? '0%' : '-100%',
                    duration: 0.35,
                    ease: 'power2.out'
                });
            }
        }
    },

    mounted() {
        this.$nextTick(() => {
            // For all menu items
            this.menu.forEach((menuItem) => {
                // If item is anchor
                if (menuItem.url.includes('#')) {
                    const anchorBlock = document.querySelector(menuItem.url).nextSibling;

                    // Add the blocks with additional infos
                    this.anchorBlocks.push({
                        url: menuItem.url,
                        top: anchorBlock.getBoundingClientRect().top + window.scrollY - this.$refs.header.clientHeight,
                        bottom: anchorBlock.getBoundingClientRect().top + window.scrollY + anchorBlock.clientHeight - this.$refs.header.clientHeight - 1,
                    });
                }
            });

            // On scroll
            window.addEventListener('scroll', () => {
                // Get scrolltop valye
                const scrolltop = window.scrollY;

                // Reset current Anchor
                this.currentAnchor = null;

                // Go through the anchors
                this.anchorBlocks.forEach((block) => {
                    // If we are in bound
                    if (scrolltop >= block.top && scrolltop <= block.bottom) {
                        // Set current anchor
                        this.currentAnchor = block.url;
                    }
                })
            });
        });

        // On resize
        window.addEventListener('resize', this.resizeHandler);

        // Do it once
        this.resizeHandler();
    },

    methods: {
        // On click on a link; check if there's an anchor to scroll properly
        checkAnchor(event) {
            // If the link contains an anchor
            if (event.target.getAttribute('href').includes('#')) {
                // Cut the normal behavior
                event.preventDefault();

                const anchorBlock = document.querySelector(event.target.getAttribute('href'));

                // Scroll to the right position
                gsap.to(window, {
                    scrollTo: anchorBlock.getBoundingClientRect().top + window.scrollY - this.$refs.header.clientHeight,
                    duration: 0.4,
                    autoKill: true,
                    ease: 'power3.out'
                })
            }
        },

        // On resize
        resizeHandler() {
            // Reset menu state
            this.menuState = false;

            // Reset position according to breakpoint
            gsap.set(this.$refs.menu, {
                display: (window.innerWidth < this.breakpoint) ? 'none' : 'flex',
                x: (window.innerWidth < this.breakpoint) ? '-100%' : '0%'
            });
        }
    }
}
</script>

<style lang="scss">
    .header-nav {
        @apply h-14;

        @screen lg {
            height: 5.5625rem;
        }

        &__logo-container {
            @apply h-10;

            @screen lg {
                height: 2.4375rem;
            }
        }

        &__menu {
            @apply top-14 left-0 flex-col fixed h-screen bg-white w-full pt-8 px-6;
            max-width: 400px;

            @screen lg {
                @apply top-auto left-auto static h-full flex flex-row justify-end items-center flex-grow p-0 ml-12 w-auto max-w-none bg-transparent transform-none translate-x-0;
            }
        }

        &__right-part a{
            @screen lg {
                line-height: 90px;
            }
        }

        &__burger-menu {
            > div {
                @apply block h-0.5 bg-red;
                width: 1.125rem;
                transform-origin: center left;
            }

            div + div{
                margin-top: 4px;
            }
        }

        &__nav-item {
            @apply inline-flex justify-between relative w-full transition-all duration-300;

            @screen lg {
                @apply w-auto;
            }

            &:before {
                content: '';
                @apply block absolute right-0 -bottom-1.5 w-0 h-px bg-red transition-all duration-300;

            }

            &.current {
                text-shadow: 0.5px 0 0 currentColor, -0.5px 0 0 currentColor;

                &:before {
                    width: 70%;
                }
            }
        }
    }
</style>
