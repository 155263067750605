<template>
    <div class="skip-links">
        <ul id="skip">
            <li>
                <a href="#mainmenu" @click.prevent="scroll">Aller au menu</a>
            </li>
            <li>
                <a href="#content" @click.prevent="scroll">Aller au contenu</a>
            </li>
            <li>
                <a href="#footer" @click.prevent="scroll">Aller au pied de page</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';

    // Get scrollto gsap plugin
    gsap.registerPlugin(ScrollToPlugin);

    export default {
        name: 'SkipLinks',

        methods: {
            scroll(pEvent) {
                const el = document.querySelector(pEvent.target.getAttribute('href'));

                gsap.set(window, {
                    scrollTo: el.getBoundingClientRect().top
                });

                el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0].focus();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .skip-links {
        a {
            display: inline-block;
            color: #555;
            background: #fff;
            padding: .5em;
            position: absolute;
            left: -99999rem;
            z-index: 100;
        }

        a:focus {
            left: 0;
        }
    }
</style>
