import "./assets/styles/main.scss";

import { createApp } from 'vue'
import CsrfInput from "@/app/components/CsrfInput";
import HeaderNav from "@/app/components/HeaderNav";
import Carousel from "@/app/components/Carousel";
import MultiCarousel from "@/app/components/MultiCarousel";
import SkipLinks from "@/app/components/SkipLinks";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        HeaderNav,
        Carousel,
        MultiCarousel,
        SkipLinks
    }
}).mount('#app');
